
  import { Component, Emit, Prop, Vue } from "vue-property-decorator";
  import { VueEditor } from "vue2-editor";

  @Component({ components: { VueEditor } })
  export default class EditorInput extends Vue {
    /**
     * Input label.
     */
    @Prop({ default: "" })
    readonly label!: string;

    /**
     * Input label.
     */
    @Prop({ default: "" })
    readonly value!: string;

    /**
     * Subtitle
     */
    @Prop({ default: "" })
    readonly subtitle!: string;

    /**
     * Emits the input event.
     * @returns String.
     */
    @Emit("input")
    onInput(data: any): string {
      return data;
    }
  }
