
  import { Component, Vue } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import LzTable from "@/components/Table.vue";
  import LzConfirm from "@/components/Confirm.vue";
  import { namespace } from "vuex-class";
  import { apiServices } from "../api";
  import LzEditorInput from "@/components/EditorInput.vue";

  const auth = namespace("auth");

  type Image = { url: string }[] | "" | ImageVModel;

  type Form = Omit<ServicePostDTO, "image_url"> & { image_url: Image };
  type FormSubmitData = ServicePostDTO;

  @Component({ components: { LzButton, LzTable, LzConfirm, LzEditorInput } })
  export default class ServiceCreate extends Vue {
    loaded = false;
    showDeleteModal = false;
    serviceId = "";
    isNewService = true;

    form: Form = {
      member_id: "",
      service_name: "",
      description: "",
      price: "",
      calendly_url: "",
      payment_in_advance: false,
      image_url: ""
    };

    @auth.State("id")
    public memberId!: string;

    async loadServiceData(serviceId: string) {
      const service = await apiServices.getById(serviceId);
      this.form = { ...service, image_url: [{ url: service.image_url }] };
    }

    async createService(body: ServicePostDTO) {
      try {
        await apiServices.create(body);
        this.$notify({
          type: "success",
          text: this.$tc("services.create.notifications.created")
        });
        this.$router.push({ name: "servicesRead" });
      } catch (error) {
        this.$notify({
          type: "error",
          text: this.$tc("common.error.generic")
        });
      }
    }

    async updateService(body: Partial<Service>) {
      try {
        await apiServices.patch(this.serviceId, body);

        this.$notify({
          type: "success",
          text: this.$tc("services.create.notifications.edited")
        });
        this.$router.push({ name: "services" });
      } catch (error) {
        this.$notify({
          type: "error",
          text: this.$tc("common.error.generic")
        });
      }
    }

    async mounted() {
      this.serviceId = this.$route.params.serviceId;

      try {
        if (!this.serviceId) {
          this.loaded = true;
          return;
        }
        await this.loadServiceData(this.serviceId);

        this.isNewService = false;
        this.loaded = true;
      } catch (error) {
        this.$notify({
          type: "error",
          text: this.$tc("common.error.generic")
        });
      }
    }

    onCancel() {
      this.$router.push({ name: "servicesRead" });
    }

    async onSave(data: FormSubmitData) {
      if (this.showDeleteModal) return;
      const body: ServicePostDTO = {
        ...data,
        image_url: await this.handleImage(this.form.image_url),
        member_id: this.memberId
      };
      if (this.isNewService) this.createService(body);
      else this.updateService(body);
    }

    confirmDeleteService() {
      this.showDeleteModal = true;
    }

    async deleteService() {
      try {
        await apiServices.delete(this.serviceId);
        this.$notify({
          type: "success",
          text: this.$tc("services.create.notifications.removed")
        });
        this.$router.push({ name: "servicesRead" });
      } catch (error) {
        this.$notify({
          type: "error",
          text: this.$tc("common.error.generic")
        });
      }
      this.showDeleteModal = false;
    }

    async handleImage(image: Image): Promise<string> {
      if (typeof image === "string") return image;
      if (Array.isArray(image)) return image[0].url;
      return await image.upload().then(([{ url }]) => url);
    }
  }
